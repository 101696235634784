body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.game {
  list-style-type: none;
  padding-left: 0px;
}
.game li{
  font-size: 20px;
}
.period .label{
  display: inline-block;
  width: 40px;
}
button.period{
  padding: 10px;
  font-size: 16pt;
  margin:5px;
}
button.add{
  padding: 40px;
  font-size: 72pt;
  margin: 5px;
}
button.goal,
button.subtract{
  padding: 30px;
  font-size: 36pt;
  margin: 5px;
}
button.goal{
  padding: 10px;
  font-size: 36pt;
  margin: 5px;
}
.score{
  height: 30px;
}

.container {
  width: 80%;
  margin: auto;
  padding: 10px;
}
.one {
  width: 50%;
  /* height: 80vh; */
  float: left;
}
.two {
  margin-left: 50%;
  /* height: 80vh; */
}
.periodContainer{
  height: 20vh;
}
.boxScorePeriod, 
.boxScoreGoal, 
.boxScore {
  display: inline-flex;
  align-items: baseline;
  width: 33%;
}
.total{
  border-top: 1px white solid;
}
.separator{
  height: 2em;
}

button {
  border-radius: 8px;
  background-color: rgb(221, 221, 221);
}
button.add {
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

button.period:hover ,
button.subtract:hover ,
button.add:hover {
  background-color: white; 
  color: black;
}
pre {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
.shuttlePlaceHolder{
  display: inline-block;
  width: 16px;
  height: 20px;
}
.boxScoreInWords{
  font-style: italic;
  padding-bottom: 10px;
  font-size: smaller;
}